import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

var animation = {
    titles: document.querySelectorAll('.text-to-animate'),
    picture: document.querySelector('.animate-main-1'),
    secondPicture: document.querySelector('.animate-main-2'),
    main: document.getElementById('set-height')
}

const gsapTimline = gsap.timeline({
    repeat: 0,
    ease: 'expo',
    onStart: () => {
        document.body.style.overflow = 'hidden';
    },
    onComplete: ()=> {
        document.body.style.overflow = 'auto';
    }
});

gsapTimline.to(animation.main, {opacity: 1, duration: 0.7})

animation.titles.forEach((title, index) => {
    if(index === 0) {
        gsapTimline.to(title, { opacity: 1, y: -10, visibility: 'visible', duration: 0.7 });
        gsapTimline.to(title, { opacity: 0, visibility: 'invisible', duration: 0.5 });
    }else {
        gsapTimline.to(title, { opacity: 1, visibility: 'visible', duration: 0.8 });
        gsapTimline.to(title, { opacity: 0, visibility: 'invisible', duration: 0.5 });
    }

    if(index === animation.titles.length - 1) {
        gsapTimline.to(animation.picture, {opacity: 1, onStart: () => {
            animation.picture.classList.add('animate-to-top');
        }})
        gsapTimline.to(animation.secondPicture, {opacity: 1, yPercent: -35})
    }
})


const tlPicture = gsap.timeline()

tlPicture.to(".animate-picture", {translateX: 0, opacity: 1,stagger: 1}, )

ScrollTrigger.create({
    animation: tlPicture,
    trigger: '.animate-section-who-we-are',
    start: "top center",
    end: "+=500",
    scrub: 1,
})

const tlHeadline = gsap.timeline()
tlHeadline.to(".headline-our-work", {translateX: 0, opacity: 1,stagger: 1}, )

ScrollTrigger.create({
    animation: tlHeadline,
    trigger: '.section-our-work',
    start: "top center",
    end: "+=500",
    scrub: 1,
})

const tlList = gsap.timeline()
tlList.to(".work-list a", {translateY: 0, opacity: 1, ease: 'sine.in' ,stagger: 1}, )

ScrollTrigger.create({
    animation: tlList,
    trigger: '.work-list',
    start: "top center",
    end: "+=500",
    scrub: 1,
})
