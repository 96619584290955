import { gsap, Sine } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Splitting from 'splitting';

gsap.registerPlugin(ScrollTrigger);
Splitting();

ScrollTrigger.create({
    trigger: '.video-section',
    start: "100",
    end: "bottom",
    scrub: 1,
    onEnter: () => {
        gsap.to(".video-section", {
            scale: 1,
            ease: 'sine.in',
            opacity: 1,
            stagger: 1
        })
    },
    onLeave: () => {
        gsap.to(".video-section", {
            scale: 1,
            ease: 'sine.in',
            opacity: 1,
            stagger: 1
        })
    }
})

const menuham = document.querySelector('.ham');
const menuIcon = document.querySelector('.ham-icon');

window.toggleNav = function() {
    const windowWidth = window.innerWidth;

    if(windowWidth < 769) {
        if(menuham.classList.contains('active')) {
            menuham.classList.remove('active');
            menuIcon.classList.remove('active')
            document.body.style.overflow = 'auto';
        }else {
            menuham.classList.add('active');
            menuIcon.classList.add('active');
            document.body.style.overflow = 'hidden';
        }
    }
}

const gsapIo = new IntersectionObserver(entries => {
    entries.forEach(entry => {
        if (entry.isIntersecting ||
            !('IntersectionObserver' in window) ||
            !('IntersectionObserverEntry' in window) ||
            !('intersectionRatio' in window.IntersectionObserverEntry.prototype)) {
            tweenMaxAnimate(entry.target, 200);
            gsapIo.unobserve(entry.target);
        }
    });
}, {rootMargin: "0px 0px -100px 0px"});

const gsapElements = document.querySelectorAll(".gsap");

for (let element of gsapElements) {
    gsapIo.observe(element);
}

function tweenMaxAnimate(target, duration) {
    const gsapTimelineElements = target.querySelectorAll('.gsap-timeline');

    setTimeout(function () {
        if (gsapTimelineElements && gsapTimelineElements.length > 0) {
            const gsapTimline = gsap.timeline({repeat: 0});

            gsapTimelineElements.forEach(function (gElement) {
                if (gElement.classList.contains('chars')) {
                    gsapTimline.to(gElement, {opacity: 1});
                    observerGSAP(gElement, '.char', {
                        opacity: 0,
                        y: 200,
                        x: 40,
                        skewX: -20,
                        skewY: -20,
                        rotation: -35,
                        delay: 300
                    }, {
                        y: 0,
                        x: 0,
                        skewX: 0,
                        skewY: 0,
                        rotation: 0,
                        opacity: 1,
                        stagger: 0.06
                    });
                } else {
                    gsapTimline.fromTo(gElement, {
                            opacity: 0,
                            x: 0,
                            y: 20,
                            scaleX: 1.01,
                            scaleY: 1.1,
                            skewY: 1,
                            duration: 0.3,
                            ease: Sine.easeIn,
                            delay: -0.05
                        },
                        {
                            opacity: 1,
                            x: 0,
                            y: 0,
                            scaleX: 1,
                            scaleY: 1,
                            skewY: 0,
                            duration: 0.3,
                            ease: Sine.easeOut,
                            delay: -0.05
                        });
                }
            });
        }
    }, duration);
}

function observerGSAP(target, selector, optionsFrom, optionsTo, delay) {
    const timelineElements = target.querySelectorAll(selector);

    if (!timelineElements) {
        return;
    }

    if (!delay) {
        delay = 0;
    }

    const timeline = gsap.timeline({defaults: {duration: 1, ease: 'expo'}});

    timeline.fromTo(timelineElements, optionsFrom, optionsTo, delay);
}

